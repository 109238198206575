import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../service/authentication.service';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {filter} from 'rxjs/operators';

/**
 * Check the permission for dealer settings section
 */
@Injectable()
export class DealerSettingsGuard {

  constructor(private store: Store<fromRoot.AppState>, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(select(fromRoot.getUserState)).pipe(
      // filter out userState objects where userInfo is not yet set
      filter(userState => !!userState.userInfo),
      map(userState => {
        const channel = route.queryParams['channel'];
        if (channel) {
          if (AuthenticationService.isAdminForChannel(channel, userState) || AuthenticationService.isSupportOrDevPoi(userState)) {
            return true;
          }
          switch (channel) {
          case 'PIA':
            return userState.isAuctionSellerPia || userState.isBuyNowSellerPia || userState.isBuyerPia;
          case 'PIA_ENFORCED':
            return AuthenticationService.isAdminForChannel('PIA', userState) || userState.isBuyerEnforcedAuction || userState.isViewerEnforcedAuction;
          case 'PB':
            return userState.isAuctionSellerPb || userState.isBuyNowSellerPb || userState.isCustodianPb;
          case 'ALL_UC':
            return userState.isAuctionSellerAllUc || userState.isBuyNowSellerAllUc || userState.isBuyerAllUc;
          case 'DIN_BIL':
            return userState.isAuctionSellerDinBil || userState.isBuyNowSellerDinBil || userState.isBuyerDinBil;
          case 'VGRX':
            return userState.isAuctionSellerVgrx || userState.isBuyNowSellerVgrx || userState.isBuyerVgrx;
          }
        }
        
        return AuthenticationService.isAdminForAnyChannel(userState) ||
          AuthenticationService.isSupportOrDevPoi(userState) ||
          userState.isSupportPb ||
          userState.isAuctionSellerAllUc ||
          userState.isAuctionSellerPia ||
          userState.isAuctionSellerPb ||
          userState.isAuctionSellerDinBil ||
          userState.isBuyNowSellerAllUc ||
          userState.isBuyNowSellerPia ||
          userState.isBuyNowSellerPb ||
          userState.isBuyNowSellerDinBil ||
          userState.isBuyerAllUc ||
          userState.isBuyerPia ||
          userState.isBuyerPb ||
          userState.isBuyerDinBil ||
          userState.isCustodianPb;
      }));
  }
}
